
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        






































.relay-offers,
[class*='relay-offers--'] {
  position: relative;
  border-top: 1px solid $c-light-gray;

  &.no-border {
    border-top: 0;
  }
}

.relay-offers-inner {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @include mq('l') {
    flex-direction: row;
  }
}
