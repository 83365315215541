
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        









































.section-hero-light,
[class*='section-hero-light--'] {
  position: relative;
  margin-top: $spacing * 5;
  padding: $spacing $wrapper-spacing-s 0;

  @include mq('xs') {
    padding-top: $spacing * 2;
  }

  @include mq('s') {
    padding-top: $spacing * 3;
  }

  @include mq('m') {
    padding: $spacing * 4 0 0;
  }

  @include mq('l') {
    padding-top: $spacing * 5;
  }

  @include mq('xl') {
    padding-top: $spacing * 6;
  }
}

.section-hero-light-inner {
  position: relative;
}

.section-hero-light__content {
  margin: 0 0 $spacing * 1.25;
  text-align: center;

  [class*='--center'] & {
    @include mq('s') {
      text-align: center;
    }
  }

  [class*='--right'] & {
    @include mq('s') {
      text-align: right;
    }
  }

  @include mq('l') {
    margin: 0 0 $spacing * 2;
    text-align: left;
  }
}

.section-hero-light__content__title {
  margin-bottom: 1rem;
}

.section-hero-light__content__subtitle {
  margin-bottom: 0;
}
