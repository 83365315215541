
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        















































































































/* stylelint-disable declaration-no-important */

.offers-packs,
[class*='offers-packs--'] {
  position: relative;
}

.offers-packs__intro {
  position: relative;
  overflow: hidden;
  margin-top: $spacing * 5;

  @include mq(m) {
    margin-top: $spacing * 10;
  }

  @include mq(xl) {
    padding-top: $spacing * 5;
    padding-bottom: $spacing * 5;
  }
}

.offers-packs__guide .offers-packs__intro__btn {
  height: auto;
  padding: $spacing * 0.5 $spacing;

  .btn__link__title {
    font-size: 1.8rem;
    line-height: 1.3;
  }

  @include mq(m) {
    padding: $spacing * 0.5 $spacing * 2;

    .btn__link__title {
      font-size: 2.4rem;
      line-height: 1;
    }
  }
}

.offers-packs__intro__show-offers {
  @extend %fw-bold;

  display: flex;
  align-items: center;
  margin-top: $spacing;
  text-decoration: none;

  @include mq(m) {
    margin-top: $spacing * 1.5;
  }
}

.offers-packs__intro__show-offers__icon {
  width: 3rem;
  height: 4rem;
  margin-right: $spacing;

  @include mq(m) {
    width: 4rem;
    height: 5rem;
  }
}

.offers-packs__intro__show-offers__label {
  position: relative;
  font-size: 1.5rem;
  line-height: 1.2;

  &::after {
    @include center-x;

    content: '';
    z-index: 0;
    bottom: -0.5rem;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $c-gray-darkest;
  }

  &::before {
    content: '';
    position: absolute;
    z-index: 1;
    bottom: -0.5rem;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    background-color: $c-pink-medium;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform 0.3s $ease-out-quad;
  }

  &:hover,
  &:focus {
    color: $c-pink-medium !important;
    background-size: 100% 1px;

    &::before {
      transform-origin: left;
      transform: scaleX(1);
    }
  }

  @include mq(m) {
    font-size: 1.8rem;
    line-height: 1.3;
  }
}

.offers-packs__guide {
  max-width: 53rem;
  margin: 0 auto;
  text-align: center;

  @include mq(m) {
    margin: 0 auto;
    padding-right: 0;
    padding-left: 0;
  }
}

.offers-packs__relay {
  padding: $spacing * 3 0;
  background-color: $c-light-gray;

  @include mq('s') {
    padding: $spacing * 4 0;
  }

  @include mq('l') {
    padding: 0;
    background-color: unset;
  }
}

.offers-packs__section {
  position: relative;
  overflow: hidden;
  padding-bottom: $spacing * 3;
}

.offers-packs__rows {
  margin-bottom: $spacing * 2;

  &:last-child {
    margin-bottom: 0;
  }

  @include mq('l') {
    margin-bottom: $spacing * 2.5;
  }
}

.offers-packs__rows__heading {
  margin-bottom: $spacing * 1.5;
  text-align: center;

  @include mq('l') {
    width: 60rem;
    margin-bottom: $spacing * 2.5;
    text-align: left;
  }

  @include mq('xl') {
    width: 80rem;
  }

  @include mq('xxl') {
    width: 100rem;
  }
}

.offers-packs__rows__heading__title {
  margin-top: 0;
  margin-bottom: $spacing / 2;
}

.offers-packs__rows__heading__subtitle {
  margin: 0;
  padding: 0 $spacing;

  @include mq('l') {
    padding: 0;
  }
}

::v-deep .cta-pack-composer {
  margin-bottom: $spacing * 3;
  padding-top: $spacing;
}

::v-deep .cta-pack-composer__cta {
  margin: 0 auto;

  @include mq($until: 'l') {
    display: none;
  }

  @include mq('l') {
    margin: 0;
  }
}

::v-deep .product-card {
  max-width: 30rem;
}

::v-deep .voo-advantages {
  flex-basis: 100%;
  width: 100%;

  @include mq('l') {
    flex-basis: 40%;
    width: 40%;
    padding-top: 10rem;
    padding-bottom: 10rem;
    padding-left: $spacing * 3;
  }

  @include mq('xl') {
    flex-basis: 50%;
    width: 50%;
    padding-left: $spacing * 4;
  }

  @include mq('xxl') {
    padding-left: $spacing * 5;
  }
}

::v-deep .section-cta__content__title {
  @include mq($until: 's') {
    font-size: 1.8rem;
  }
}

.offers-packs__rows__item {
  @include mq($until: 'm') {
    margin-bottom: $spacing * 2.5;
  }
}

.offers-packs__banner {
  @include mq($until: 'm') {
    padding-right: 0;
    padding-left: 0;
  }
}
