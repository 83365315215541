
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        










































































.banner-comparator {
  position: relative;
  padding: 0 0 $spacing * 3;
  margin: auto;

  @include mq('l') {
    padding: 4.4rem 0 11.4rem;
    width: 60rem;
  }

  @include mq('xl') {
    width: 80rem;
  }

  @include mq('xxl') {
    width: 100rem;
  }
}
.banner-comparator-inner {
  position: relative;

  @include mq($until: 'm') {
    color: $white;
    background: $c-bunting;
  }

  @include mq('m') {
    padding: 55px 40px;
    border-radius: 20px;
    box-shadow: 0px 10px 40px 0px rgba(0, 0, 0, 0.1);
    background: white;
  }
}

.banner-comparator__speedlinesBg {
  z-index: 0;
  right: 0;

  @include mq($until: 'm') {
    display: none;
  }
}

.banner-comparator__content {
  @include mq($until: 'm') {
    padding: 2.5rem;
  }
  @include mq('xl') {
    max-width: 55%;
  }
}

.banner-comparator__title {
  margin-top: 0;
  margin-bottom: 1.6rem;
}

.banner-comparator__text {
  margin-bottom: 1.6rem;
}

.banner-comparator__link {
  @include mq($until: 'm') {
    display: none !important;
  }
}
.banner-comparator__btn {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1rem 2rem;

  @include mq('m') {
    display: none;
  }
}

.banner-comparator__speedlines-wrapper {
  display: none;
  overflow: hidden;

  @include mq('xl') {
    position: absolute;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 24rem;
    width: 50%;
    height: calc(100% + 5rem);
  }
}

.banner-comparator__speedlines {
  top: 3rem;
  height: 100%;
  transform: scale(1.2);

  @include mq('l') {
    top: $spacing;
    right: $spacing;
    width: auto;
    height: 130%;
    transform: scale(1);
  }

  @include mq('xxl') {
    top: 0;
  }
}
