
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        



















































































.grid-packs-inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 4rem 2rem;
  margin-bottom: $spacing * 3;

  @include mq('l') {
    justify-content: flex-start;

    ::v-deep {
      .product-card {
        width: calc(25% - 2rem);
        min-width: 28rem;
      }
    }
  }
}
